@import "normalize";
@import "main-page";

$body-color: #7f7f7f;

body,
html {
  min-height: 100vh;
}

body {
  color: $body-color;
  font-family: dosis, sans-serif;
}
