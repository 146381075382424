.p-main-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  background-size: cover;
  min-height: 100vh;

  .note-wrapper {
    position: absolute;
    top: 4rem;
    left: 1rem;
    text-align: center;
    transform: rotate(-45deg);

    .main-note,
    .side-note {
      font-size: 18px;
      font-weight: 600;
      color: #661778;
    }

    .side-note {
      font-size: 12px;
    }
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;

    .logo-img {
      height: 150px;
      width: 410px;
      background-size: cover;
      background-position: center;
    }
  }

  .main-text {
    padding-bottom: 50px;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;

    h1 {
      margin: 0;
      font-family: "Raleway", sans-serif;
      font-size: 50px;
      font-weight: 700;
      color: #661778;
    }

    h2 {
      margin: 0 auto 1em auto;
      max-width: 700px;
      font-size: 35px;
      font-weight: 300;
    }

    .spacer {
      margin: 0 auto;
      width: 100px;
      height: 10px;
      border-radius: 5px;
      background-color: #661778;
    }
  }

  .button-wrapper {
    margin-top: 20px;
  }

  .main-button {
    display: inline-block;
    padding: 10px 30px;
    color: #661778;
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    transition: all 0.5s;
    font-weight: 600;
    font-size: 20px;

    &:hover {
      transition: all 0.5s;
      color: #fff;
      background-color: #661778;
    }
  }

  .contact {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.6);

    .contant-row {
      display: flex;
      justify-content: space-around;

      p {
        padding: 0 10px;
        font-size: 20px;
      }
    }
  }

  .team-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    overflow-y: auto;

    .modal-header {
      position: relative;
      text-align: center;
    }

    .modal-title {
      font-size: 32px;
    }

    .close-btn {
      position: absolute;
      right: 32px;
      top: 0;
      width: 32px;
      height: 32px;
      opacity: 0.3;
      cursor: pointer;
    }

    .close-btn:hover {
      opacity: 1;
    }

    .close-btn::before,
    .close-btn::after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 2px;
      background-color: #333;
    }

    .close-btn::before {
      transform: rotate(45deg);
    }

    .close-btn::after {
      transform: rotate(-45deg);
    }

    // team section css
    .team {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      padding: 60px;
      max-width: 800px;

      .team-member {
        display: flex;
        padding: 10px;
        width: calc(50% - 20px);

        .img {
          height: 150px;
          width: 150px;
          background-size: cover;
          background-position: top center;
        }

        .text {
          padding-left: 20px;
        }

        .name {
          margin: 0.5rem;
          margin-bottom: 0;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid #7f7f7f;
        }

        .description {
          margin: 0.5rem;
        }
        // goes on team-member when using person img
        &.no-img .img {
          transform: scale(0.75);
        }
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 50px 25px;

    .logo-wrapper {
      padding-bottom: 20px;

      .logo-img {
        height: 75px;
        width: 200px;
      }
    }

    .main-text {
      padding-bottom: 25px;

      h1 {
        font-size: 26px;
      }

      h2 {
        max-width: 50%;
        font-size: 16px;
      }

      .spacer {
        width: 50px;
        height: 6px;
        border-radius: 3px;
      }
    }

    .main-button {
      font-size: 16px;
    }

    .contact {
      padding: 10px;

      .contant-row {
        flex-direction: column;
        align-items: center;

        p {
          margin: 0.2em;
          padding: 0 5px;
          font-size: 16px;
        }
      }
    }

    .team-modal .modal-title {
      font-size: 22px;
    }
  }

  @media (max-width: 768px) {
    .note-wrapper {
      top: 3rem;

      .main-note {
        font-size: 14px;
      }

      .side-note {
        display: none;
      }
    }

    .main-text h2 {
      max-width: 100%;
    }

    .team-modal .team {
      max-width: 350px;
      padding: 30px;

      .team-member {
        width: calc(100% - 20px);

        .img {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}
